import { useId } from "react"

import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"
import { isBlockedIpError } from "@supernovaio/cloud/utils/errorHandler"

import { useSafeQuery } from "../useSafeQuery"

export const createWorkspaceQueryKey = (ids: { wsId: string }) => [
  "workspace",
  { ...ids },
  "isIpLocked",
]

export const useIsWorkspaceIpLocked = (wsId: string | undefined) => {
  const wsIdFallback = useId()

  return useSafeQuery({
    queryKey: createWorkspaceQueryKey({
      wsId: wsId || wsIdFallback,
    }),
    queryFn: async () => {
      if (!wsId) {
        return null
      }

      const sdk = await getClientSdk()

      try {
        await sdk.workspaces.workspace(wsId)
      } catch (error) {
        if (isBlockedIpError(error)) {
          return true
        }
      }

      return false
    },
  })
}
