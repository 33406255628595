import { useFlags } from "launchdarkly-react-client-sdk"

export type FeatureFlags = {
  allowReviewProcess: boolean
  allowPageDrafts: boolean
  showComments: boolean
  showTokenCollections: boolean
  isNewOnboardingEnabled: boolean
  supportReferenceResolutionErrors: boolean
  mockBlockDefinitions: boolean
  allowContributorRole: boolean
  enableRestrictedDocSettingsAccess: boolean
  enableGroupsInAssetsBlock: boolean
  allowClosedDs: boolean
  enableMultipleThemesInTokenBlocks: boolean
  enableMultipleThemesInPipelines: boolean
  allowDesignSystemRoles: boolean
  allowMultipleOwners: boolean
  printAclDebugInfo: boolean
  allowDesignSystemOverview: boolean
}

export function useFeatureFlags() {
  return useFlags<FeatureFlags>()
}
