export const entityAlphabeticalSort = <T>(
  arrayOfEntities: T[],
  selectStringFn: (entity: T) => string
): T[] =>
  arrayOfEntities.sort((a, b) =>
    selectStringFn(a).localeCompare(selectStringFn(b))
  )

/*
 * Sorts the provided array in the alphabetical order of the selected string.
 * @param arrayOfEntities - The array of entities to sort.
 * @param selectStringFn - The function that selects the string to sort by.
 * @returns a sorted array (new instance).
 */
export const createSortedCopy = <T>(
  arrayOfEntities: T[],
  selectStringFn: (entity: T) => string
): T[] =>
  arrayOfEntities.toSorted((a, b) =>
    selectStringFn(a).localeCompare(selectStringFn(b))
  )
