import { notFound } from "next/navigation"

import { Supernova } from "@supernovaio/sdk"

import { isBlockedIpError } from "../errorHandler"

import { getLockedAccessRoute } from "./getLockedAccessRoute"
import { getReadableRoute } from "./getReadableRoute"

export async function getPartialBaseRoute<
  WsId extends string,
  DsId extends string,
  VersionId extends string,
  BrandId extends string
>(
  sdk: Supernova,
  wsId?: WsId | undefined,
  dsId?: DsId | undefined,
  versionId?: VersionId | undefined,
  brandId?: BrandId | undefined
) {
  // Note this counts on proper order (ie if dsId is missing, everything else is missing). This is universally developer error if such configuration is provided

  if (wsId) {
    let workspace
    try {
      workspace = await sdk.workspaces.workspace(wsId)
    } catch (e) {
      if (isBlockedIpError(e)) {
        return getLockedAccessRoute(wsId)
      }
    }

    if (!workspace) {
      notFound()
    }

    if (dsId) {
      const designSystem = await sdk.designSystems.designSystem(dsId)

      if (!designSystem) {
        notFound()
      }

      if (versionId) {
        const version = await sdk.versions.getVersion({
          designSystemId: dsId,
          versionId,
        })

        if (!version) {
          notFound()
        }

        if (brandId) {
          const brand = await sdk.brands.getBrand({
            brandId,
            versionId,
            designSystemId: dsId,
          })
          if (!brand) {
            notFound()
          }
          return getReadableRoute(workspace, designSystem, version, brand)
        }

        return getReadableRoute(workspace, designSystem, version)
      }

      return getReadableRoute(workspace, designSystem)
    }

    return getReadableRoute(workspace)
  }

  return "/" as const
}
