import { AclOperation, AclOperations } from "@supernova-studio/acl"

export const CODE_PAGES_PERMISSIONS: AclOperation[] = [
  AclOperations.ExporterRead,
  AclOperations.ViewCodegenData,
]

export const PIPELINES_CREATE_PERMISSIONS: AclOperation[] = [
  AclOperations.WorkspacePipelinesCreate,
]

export const EXPORTER_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.ExporterUpdate,
]

export const DESIGN_DATA_PAGES_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemVersionContentRead,
  AclOperations.ViewFigmaDesignData,
]

export const DESIGN_DATA_CREATE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemVersionContentCreate,
]

export const DESIGN_DATA_UPDATE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemVersionContentUpdate,
]

export const COMPONENT_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemComponentRead,
]

export const COMPONENT_CREATE_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemComponentCreate,
]

export const COMPONENT_UPDATE_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemComponentUpdate,
]

export const DESIGN_SYSTEM_VERSION_CREATE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemVersionCreate,
]

export const SELECT_PLAN_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.WorkspaceSubscriptionUpdate,
]

export const TOKEN_CREATE_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemTokenCreate,
]

export const TOKEN_UPDATE_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemTokenUpdate,
]

export const ASSETS_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemAssetRead,
]

export const ASSETS_FONTS_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemFontRead,
  AclOperations.ViewFontsPage,
]

export const DOCS_SETTINGS_PERMISSIONS: AclOperation[] = [
  AclOperations.UpdateDocumentationSettings,
]

export const DOCS_PAGES_PERMISSIONS: AclOperation[] = [
  AclOperations.DocumentationUpdate,
]

export const DOCS_PREVIEW_PERMISSIONS: AclOperation[] = [
  AclOperations.PreviewDocumentation,
]

export const DOCS_PUBLISH_PERMISSIONS: AclOperation[] = [
  AclOperations.PublishDocumentation,
]

export const SETTINGS_WS_GENERAL_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.WorkspaceUpdate,
]

export const SETTINGS_SSO_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.WorkspaceSsoManage,
]

export const SETTINGS_IP_WHITELIST_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.WorkspaceWhitelistManage,
]

export const SETTINGS_WS_BILLING_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.WorkspaceSubscriptionRead,
  AclOperations.ViewBillingPage,
]

export const SETTINGS_WS_MEMBERS_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.WorkspaceMembersRead,
  AclOperations.ViewWorkspaceMembersPage,
]

export const SETTINGS_DESIGN_SYSTEMS_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemRead,
  AclOperations.ViewWorkspaceDesignSystemsPage,
]

export const SETTINGS_DS_GENERAL_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemUpdate,
]

export const SETTINGS_DS_MEMBERS_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemMemberRead,
  AclOperations.ViewWorkspaceDesignSystemMembersPage,
]

export const SETTINGS_DS_VERSIONS_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemVersionRead,
]

export const SETTINGS_DS_BRANDS_PAGE_PERMISSIONS: AclOperation[] = [
  AclOperations.DesignSystemBrandRead,
  AclOperations.ViewBrandsPage,
]

export const SETTINGS_WS_INTEGRATION_READ_PERMISSIONS: AclOperation[] = [
  AclOperations.WorkspaceIntegrationsRead,
]

export const SETTINGS_WS_INTEGRATION_FIGMA_PROVIDERS_CREATE_PERMISSIONS: AclOperation[] =
  [AclOperations.WorkspaceIntegrationsCreate]

export const SETTINGS_WS_INTEGRATION_GIT_PROVIDERS_CREATE_PERMISSIONS: AclOperation[] =
  [AclOperations.WorkspaceIntegrationsCreate]

export const SETTINGS_WS_INTEGRATION_FIGMA_PROVIDERS_DELETE_PERMISSIONS: AclOperation[] =
  [AclOperations.WorkspaceIntegrationsDelete]

export const SETTINGS_WS_INTEGRATION_GIT_PROVIDERS_DELETE_PERMISSIONS: AclOperation[] =
  [AclOperations.WorkspaceIntegrationsDelete]
