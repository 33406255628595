import { env } from "@supernovaio/cloud/env.mjs"
import {
  getAccessToken,
  getSessionInMemory,
} from "@supernovaio/cloud/features/auth"
import { Supernova } from "@supernovaio/sdk"

const sdkCache: { token: string; sdk: Supernova }[] = []

export async function getClientSdk() {
  const session = await getSessionInMemory()
  const token = getAccessToken(session)
  const cachedSdk = sdkCache.find((item) => item.token === token)

  if (cachedSdk) {
    return cachedSdk.sdk
  }

  const sdk = new Supernova(token, {
    apiUrl: env.NEXT_PUBLIC_SN_API_URL,
  })

  sdkCache.push({
    token,
    sdk,
  })

  return sdk
}

/**
 * Returns SDK without access to data layer.
 * Only functions which don't require fetching data will be working.
 * Useful for using utils etc.
 */
export function getStaticClientSdk() {
  const token = getAccessToken(null)
  const cachedSdk = sdkCache.find((item) => item.token === token)

  if (cachedSdk) {
    return cachedSdk.sdk
  }

  const sdk = new Supernova(token, {
    apiUrl: env.NEXT_PUBLIC_SN_API_URL,
  })

  sdkCache.push({
    token,
    sdk,
  })

  return sdk
}
