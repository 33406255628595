import { Session } from "next-auth"
import { getSession } from "next-auth/react"

let sessionInMemory: Session | null = null

/**
 * @description It is there so that we don’t have to make network requests for the session if it already exists on the client
 */
export async function getSessionInMemory() {
  if (sessionInMemory) {
    return sessionInMemory
  }

  const session = await getSession()

  if (!session?.user) {
    return null
  }

  updateSessionInMemory(session)

  return session
}

export function updateSessionInMemory(session: Session) {
  sessionInMemory = session
}
