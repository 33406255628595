//
//  SDKWorkspaceSubscriptionsStatus.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Workspace subscription status enum

export enum WorkspaceSubscriptionStatus {
  /** The subscription is active */
  active = "active",

  /** The subscription is on a grace period */
  gracePeriod = "gracePeriod",

  /** The subscription is cancelled */
  cancelled = "cancelled",

  /** The subscription is suspended */
  suspended = "suspended",
}
