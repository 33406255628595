import { useTranslations } from "next-intl"

import { DMPageLoader } from "@supernovaio/dm"

type PageLoaderProps = {
  showTitle?: boolean
}

export default function PageLoader({ showTitle = false }: PageLoaderProps) {
  const t = useTranslations("general")

  return <DMPageLoader title={showTitle ? t("appLoadingTitle") : null} />
}
