"use client"

import { useEffect } from "react"

import {
  usePathname,
  useRouter,
  useSelectedLayoutSegment,
} from "next/navigation"

import { useFeatureFlags } from "../utils/featureFlags/useFeatureFlags"
import { DOCS_PAGES_PERMISSIONS } from "../utils/permissions"
import { getFullBaseRoute } from "../utils/routing/getFullBaseRoute"
import { getSettingsRouteForInternalRedirect } from "../utils/routing/getSettingsRoute"
import { isSettingsRoute } from "../utils/routing/isSettingsRoute"

import { useRoutingResolution } from "./routing/useRoutingResolution"

import { usePermissions } from "./usePermissions"

/*
 * This hook is used to redirect the user to a new route.
 *
 * There are two main cases where this hook is used:
 * 1. Redirect from routes that are not supposed to be accessed directly.
 * These routes require additional segments to become a meaningful path with a corresponding page,
 * e.g. "/{wsId}/{dsId}" -> "/{wsId}/{dsId}/{vsId}/{brandId}/documentation".
 *
 * 2. Redirect from empty states (no workspaces or design systems).
 * When user creates a new workspace or design system, they are redirected to it right away by this hook.
 *
 */
export const useClientSideRedirect = () => {
  const router = useRouter()
  const pathname = usePathname()
  const segment = useSelectedLayoutSegment()
  const resolution = useRoutingResolution()
  const { hasPermissionNew } = usePermissions(
    resolution?.wsId,
    resolution?.dsId
  )
  const { isNewOnboardingEnabled } = useFeatureFlags()

  useEffect(() => {
    ;(async function updateURLWithFullBaseRoute() {
      let route

      if (isSettingsRoute(pathname)) {
        // If needed, redirect to another tab within the Settings page or update ids in the route
        route = await getSettingsRouteForInternalRedirect({
          resolution,
          pathname,
          segment,
        })
      } else {
        // Redirect to a new page within the app if needed
        route = await getFullBaseRoute(
          resolution,
          hasPermissionNew(DOCS_PAGES_PERMISSIONS),
          isNewOnboardingEnabled
        )
      }

      if (!route || pathname === route) {
        return
      }

      router.replace(route)
    })()
  }, [
    hasPermissionNew,
    resolution,
    router,
    pathname,
    segment,
    isNewOnboardingEnabled,
  ])

  return { resolution }
}
