import { useParams as useNavigationParams } from "next/navigation"

import { BrandedPageParams } from "../app/[locale]/[wsId]/[dsId]/[versionId]/[brandId]/types"
import { normalizeParams } from "../utils/routing/normalizeParams"

/**
 * Define T as true to get all params as required
 */
export function useParams<MandatoryParams>() {
  const params =
    useNavigationParams() as unknown as MandatoryParams extends true
      ? BrandedPageParams
      : Partial<BrandedPageParams>

  return normalizeParams(params)
}
