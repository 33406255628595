import { useCallback, useId } from "react"

import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"

import { useQueryClient } from "@tanstack/react-query"

import { useSafeQuery } from "../useSafeQuery"

export const createWorkspaceQueryKey = (ids: { wsId: string }) => [
  "workspace",
  { ...ids },
]

export const useInvalidateWorkspace = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (ids: { wsId: string }) =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: createWorkspaceQueryKey(ids),
        }),
      ]),
    [queryClient]
  )
}

export const useWorkspace = (wsId: string | undefined, retryOnMount = true) => {
  const wsIdFallback = useId()

  return useSafeQuery({
    queryKey: createWorkspaceQueryKey({ wsId: wsId || wsIdFallback }),
    queryFn: async () => {
      if (!wsId) {
        return null
      }

      const sdk = await getClientSdk()

      return sdk.workspaces.workspace(wsId)
    },
    retryOnMount,
  })
}
