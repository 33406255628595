"use client"

import { useParams } from "../../useParams"
import { resolveBaseRouteIds, useStoredRouteIds } from "../useRouteIds"

import type { ResolverOptions, RoutingResoluton } from "./types"
import { useRoutingResolver } from "./useRoutingResolver"

/**
 * @param options If `customWsId` is used, resolver will require workspace to exist and be accessible.
 * It won't fallback to first possible workspace for the user.
 * @returns When undefined is returned it means that resolution is still resolving.
 */
export function useRoutingResolution(
  options?: ResolverOptions
): RoutingResoluton {
  const initialRouteIds = useParams()
  const storedRouteIds = useStoredRouteIds()
  const routeIds = resolveBaseRouteIds(initialRouteIds, storedRouteIds)

  return useRoutingResolver(routeIds, options)
}
