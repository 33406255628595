type ClampValueInput = {
  value: number | null
  min: number
  max: number
}

export const clampValue = ({ value, min, max }: ClampValueInput): number => {
  if (!value || !Number.isFinite(value)) {
    return 0
  }

  return Math.min(Math.max(value, min), max)
}

export function getEnumKeyByValue<T extends { [index: string]: string }>(
  enumObj: T,
  value: T[keyof T]
): string {
  const result = Object.keys(enumObj).find(
    (key) => enumObj[key as keyof typeof enumObj] === value
  )

  if (!result) {
    throw new Error(`No key found for value: ${value}`)
  }

  return result
}

/**
 * Returns the actual enum value matching the provided string representation.
 * Eg. if we store an enum into local storage as a string, we need some way to get the enum value back
 *
 * ```
 * enum TestEnum {
 *   first = "FIRST VALUE",
 *   second = "SECOND VALUE"
 * }
 *
 * parseEnumValueFromString(TestEnum, "SECOND VALUE") // returns TestEnum.second
 * ```
 */
export function parseEnumValueFromString<T extends object>(
  enumType: T,
  value: string
): T[keyof T] | undefined {
  return Object.values(enumType).find((v) => v === value) as T[keyof T]
}

export function generateRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
