import { Session } from "next-auth"
import { signOut } from "next-auth/react"

import { env } from "@supernovaio/cloud/env.mjs"
import { resetSegment } from "@supernovaio/cloud/features/segment"
import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"
import { getHomeRoute } from "@supernovaio/cloud/utils/routing/getHomeRoute"

import { getImpersonationData, removeImpersonation } from "../impersonation"

export function generateAuthParams(
  email: string | null,
  ssoProvider?: string | null,
  requiresSignup?: boolean
) {
  const params = new URLSearchParams()

  if (email) {
    params.set("email_hint", email)
    params.set("email_readonly", "true")
  }

  if (ssoProvider) {
    params.set("sso_provider", ssoProvider)
  }

  if (email || requiresSignup) {
    params.set("initial_tab", "signup")
  }

  return params.toString() ? params : undefined
}

export function isAuthDebugEnabled() {
  return (
    env.NEXT_PUBLIC_SN_APP_ENV === "local" && env.NEXT_PUBLIC_AUTH_DEBUG === "1"
  )
}

/**
 * @description If we expired token from `AuthDebugOverlay`, expired token will be returned (if it still exists).
 * If we are impersonating user, impersonated token will be returned.
 * If we have valid token, it will be returned.
 * Otherwise, "missing-token" value will be returned.
 */
export function getAccessToken(session: Session | null) {
  if (typeof window !== "undefined" && window.supernova?.expiredAccessToken) {
    return window.supernova.expiredAccessToken
  }

  const { token: impersonatedToken } = getImpersonationData()

  if (impersonatedToken) {
    return impersonatedToken
  }

  if (session?.user?.accessToken) {
    return session.user.accessToken
  }

  return "missing-token"
}

export async function logout(options?: {
  homeRouteSearchParams?: URLSearchParams
  /** Required if we are doing logout after user removal */
  skipSdkLogout?: boolean
}) {
  const sdk = await getClientSdk()

  removeImpersonation(false)

  if (!options?.skipSdkLogout) {
    // Logout user from docs
    await sdk.me.logout()
  }

  await resetSegment()

  // Remove app session
  return signOut({
    callbackUrl: getHomeRoute(options?.homeRouteSearchParams),
  })
}
