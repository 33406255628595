import { shallowCompareObjects } from "../../../utils/shallowCompare"

import type {
  RecentRouteIdsScheme,
  RecentPossibleBrandIds,
  RouteIds,
} from "./types"

const STORED_IDS_LIMIT = 20

const addNonDuplicateToArray = <T extends { [key: string]: string }>(
  array: T[] | undefined,
  itemToAdd: T | undefined
) => {
  if (!itemToAdd) {
    return array || []
  }

  const filteredArray = (array || []).filter(
    (item) => !shallowCompareObjects(item, itemToAdd)
  )

  return filteredArray
    .slice(Math.max(0, filteredArray.length - STORED_IDS_LIMIT))
    .concat([itemToAdd])
}

export const idsToStorage = (
  ids: Partial<RouteIds>,
  prevStorageIds: RecentRouteIdsScheme | undefined
): { [key in keyof RecentRouteIdsScheme]: RecentRouteIdsScheme[key] } => ({
  wsIds: addNonDuplicateToArray(
    prevStorageIds?.wsIds,
    "wsId" in ids && ids.wsId ? { wsId: ids.wsId } : undefined
  ),

  dsIds: addNonDuplicateToArray(
    prevStorageIds?.dsIds,
    "dsId" in ids && "wsId" in ids && ids.wsId && ids.dsId
      ? { parentWsId: ids.wsId, dsId: ids.dsId }
      : undefined
  ),

  brandIds: addNonDuplicateToArray(
    prevStorageIds?.brandIds,
    "dsId" in ids &&
      "brandId" in ids &&
      "versionId" in ids &&
      ids.versionId &&
      ids.dsId &&
      ids.brandId
      ? {
          brandId: ids.brandId,
          parentDsId: ids.dsId,
          parentVersionId: ids.versionId,
        }
      : undefined
  ),
  documentationPageIds: addNonDuplicateToArray(
    prevStorageIds?.documentationPageIds,
    ids.brandId && ids.documentationPageId && ids.versionId
      ? {
          parentVersionId: ids.versionId,
          parentBrandId: ids.brandId,
          documentationPageId: ids.documentationPageId,
        }
      : undefined
  ),
})

export const pickLastRecentId = <T extends { [key: string]: string }>(
  firstPick: string | undefined,
  objectArray: T[],
  picker: (item: T) => string | false | null | undefined
): string => {
  if (firstPick) {
    return firstPick
  }

  return [...objectArray].reverse().map(picker).find(Boolean) || ""
}

export const resolveBaseRouteIds = (
  urlParams: {
    wsId?: string
    dsId?: string
    versionId?: string
    brandId?: string
  },
  storageIds: RecentRouteIdsScheme | undefined
): RouteIds & {
  possibleBrandIds: RecentPossibleBrandIds
} => {
  if (!storageIds) {
    return {
      ...urlParams,
      possibleBrandIds: [],
    }
  }

  const possibleWsId = pickLastRecentId(
    urlParams.wsId,
    storageIds.wsIds,
    ({ wsId }) => wsId
  )

  const possibleDsId = pickLastRecentId(
    urlParams.dsId,
    storageIds.dsIds,
    ({ parentWsId, dsId }) => possibleWsId === parentWsId && dsId
  )

  return {
    wsId: possibleWsId,
    dsId: possibleDsId,
    brandId: urlParams.brandId,
    versionId: urlParams.versionId,
    possibleBrandIds: !urlParams.brandId
      ? storageIds.brandIds
          .reverse()
          .map(({ parentDsId, brandId, parentVersionId }) =>
            possibleDsId === parentDsId && brandId
              ? { brandId, versionId: parentVersionId }
              : false
          )
          .filter(
            (
              possibleBrandId
            ): possibleBrandId is { brandId: string; versionId: string } =>
              Boolean(possibleBrandId)
          )
      : [],
  }
}
