import { useStorageValueMap } from "../../usePersistentStorage"

import { recentRouteIdsSchema } from "./schema"
import type { RecentRouteIdsScheme } from "./types"

const RECENT_ROUTE_IDS_STORAGE_KEY = "recentRouteIds" as const

const defaultRouteIds: RecentRouteIdsScheme = {
  wsIds: [],
  dsIds: [],
  brandIds: [],
  documentationPageIds: [],
}

export const useStoredRouteIds = (): RecentRouteIdsScheme => {
  const map = useStorageValueMap(recentRouteIdsSchema, (storage) =>
    storage.getItem(RECENT_ROUTE_IDS_STORAGE_KEY)
  )

  return map((ids) => ids) || defaultRouteIds
}
