import { useCallback, useId } from "react"

import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"

import { useQueryClient } from "@tanstack/react-query"

import { useSafeQuery } from "../useSafeQuery"

export const createDesignSystemQueryKey = (ids: { dsId: string }) => [
  "designSystem",
  { ...ids },
]

export const useInvalidateDesignSystem = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (ids: { dsId: string }) =>
      queryClient.invalidateQueries({
        queryKey: createDesignSystemQueryKey(ids),
      }),
    [queryClient]
  )
}

export const useDesignSystem = (dsId?: string) => {
  const fallbackDsId = useId()

  return useSafeQuery({
    queryKey: createDesignSystemQueryKey({ dsId: dsId || fallbackDsId }),
    queryFn: async () => {
      if (!dsId) {
        return null
      }

      const sdk = await getClientSdk()

      return sdk.designSystems.designSystem(dsId)
    },
  })
}

export const usePrefetchDesignSystem = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (dsId: string) =>
      queryClient.prefetchQuery({
        queryKey: createDesignSystemQueryKey({ dsId }),
        queryFn: async () => {
          const sdk = await getClientSdk()

          return sdk.designSystems.designSystem(dsId)
        },
      }),
    [queryClient]
  )
}
