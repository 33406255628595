import { useId } from "react"

import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"

import { useSafeQuery } from "./useSafeQuery"

export const createAclsQueryKey = (ids: { wsId: string }) => [
  "acls",
  { ...ids },
]

export function useAcls(wsId?: string) {
  const wsIdFallback = useId()

  return useSafeQuery({
    queryKey: createAclsQueryKey({ wsId: wsId || wsIdFallback }),
    queryFn: async () => {
      const sdk = await getClientSdk()

      return sdk.workspaces.acls()
    },
  })
}
