import { useCallback } from "react"

import { useDesignSystem } from "@supernovaio/cloud/hooks/data/useDesignSystem"
import { useCurrentMembership } from "@supernovaio/cloud/hooks/useCurrentMembership"
import { useFeatureFlags } from "@supernovaio/cloud/utils/featureFlags/useFeatureFlags"
import { UserRole } from "@supernovaio/sdk"

type CallbackProps = {
  /**
   * Flag that determines what scope the requested action belongs to.
   * If true, the returned role is always the WS role.
   */
  isWorkspaceScope: boolean
}

type RoleResult = {
  data: UserRole | undefined
  isPending: boolean
}

/**
 * Returns the function to fetch the current effective role of the user.
 *
 * This hook should be used in most cases when we check if the user has permissions to perform a specific action.
 *
 * However, we need to use the `useCurrentMembership` or `useDesignSystem` hooks directly
 * in the context of WS/DS Members management.
 *
 * @param wsId
 * @param dsId
 */
export function useGetCurrentRole(
  wsId: string | undefined,
  dsId: string | undefined
) {
  const { allowDesignSystemRoles } = useFeatureFlags()

  const { membership, isPending: isPendingMembership } = useCurrentMembership({
    wsId,
  })
  const { data: designSystem, isPending: isPendingDesignSystem } =
    useDesignSystem(dsId)

  return useCallback<(props: CallbackProps) => RoleResult>(
    ({ isWorkspaceScope }) => {
      if (isPendingMembership || isPendingDesignSystem) {
        return { data: undefined, isPending: true }
      }

      if (allowDesignSystemRoles) {
        // [New behavior] Support DS role

        if (!dsId || isWorkspaceScope) {
          // Fallback to WS role right away, since DS id is not defined or we are in WS scope
          return {
            data: membership?.role,
            isPending: false,
          }
        }

        if (!designSystem) {
          // DS id is provided, but the DS is loading, so we can't determine the role's source yet
          return { data: undefined, isPending: true }
        }

        // When there is no DS role - inherit role from WS
        return {
          data: designSystem.effectiveRole || membership?.role,
          isPending: false,
        }
      }

      // [Old behavior] Use only WS role
      return {
        data: membership?.role,
        isPending: false,
      }
    },
    [
      isPendingMembership,
      isPendingDesignSystem,
      allowDesignSystemRoles,
      membership?.role,
      dsId,
      designSystem,
    ]
  )
}
