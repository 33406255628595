import { getClientSdk } from "../data/getClientSdk"
import { isUnauthorizedError } from "../errorHandler"
import { wait } from "../helpers"

import { getReadableRoute } from "./getReadableRoute"
import {
  BrandRouteDestinationType,
  BrandRouteType,
  BrandRouteWithDestinationType,
} from "./types"

/**
 * @deprecated
 */
export async function getBrandRoute<
  WsId extends string,
  DsId extends string,
  VersionId extends string,
  BrandId extends string
>(
  wsId: WsId,
  dsId: DsId,
  versionId: VersionId,
  brandId: BrandId,
  _internalRetryCounter = 0
): Promise<BrandRouteType> {
  try {
    const sdk = await getClientSdk()

    const workspace = await sdk.workspaces.workspace(wsId)
    const designSystem = await sdk.designSystems.designSystem(dsId)

    const version = await sdk.versions.getVersion({
      designSystemId: dsId,
      versionId,
    })

    const brand = await sdk.brands.getBrand({
      brandId,
      versionId,
      designSystemId: dsId,
    })

    if (!workspace) {
      throw Error("missing workspace")
    }

    if (!designSystem) {
      throw Error("missing design system")
    }

    if (!version) {
      throw Error("missing version")
    }

    if (!brand) {
      throw Error("missing brand")
    }

    return getReadableRoute(workspace, designSystem, version, brand)
  } catch (error) {
    if (isUnauthorizedError(error) && _internalRetryCounter < 3) {
      window.dispatchEvent(new CustomEvent("request-session-update"))

      // Hack, hopefully we will be able to remove it later on.
      // It's needed because we need to wait for session to update before we will call api again.
      await wait(1000)

      return getBrandRoute(
        wsId,
        dsId,
        versionId,
        brandId,
        _internalRetryCounter + 1
      )
    }

    throw error
  }
}

export async function getBrandRouteWithDestination<
  WsId extends string,
  DsId extends string,
  VersionId extends string,
  BrandId extends string,
  Destination extends BrandRouteDestinationType
>(
  wsId: WsId,
  dsId: DsId,
  versionId: VersionId,
  brandId: BrandId,
  destination: Destination
): Promise<BrandRouteWithDestinationType> {
  const brandRoute = await getBrandRoute(wsId, dsId, versionId, brandId)

  return `${brandRoute}/${destination}`
}
