import { useCallback, useId } from "react"

import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"
import { DesignSystemVersion } from "@supernovaio/sdk"

import { useQueryClient } from "@tanstack/react-query"

import { useSafeQuery } from "../useSafeQuery"

type QueryIds = { dsId: string }

export const createVersionsQueryKey = (ids: QueryIds) => [
  { ...ids },
  "versions",
]

export const useInvalidateVersions = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (ids: QueryIds) =>
      queryClient.invalidateQueries({
        queryKey: createVersionsQueryKey(ids),
      }),
    [queryClient]
  )
}

// It is reused in useLastVersions hook. Needs to be same because of caching
export const queryFn = (dsId: string | undefined) => async () => {
  if (!dsId) {
    return []
  }

  const sdk = await getClientSdk()

  return sdk.versions.getVersions(dsId)
}

export const selectFn = (versions: DesignSystemVersion[]) => {
  return versions.sort((a, b) => {
    if (a.isReadonly) {
      return 1
    }

    return b.createdAt.getTime() - a.createdAt.getTime()
  })
}
export const useVersions = (dsId: string | undefined) => {
  const fallbackDsId = useId()

  return useSafeQuery({
    queryKey: createVersionsQueryKey({ dsId: dsId || fallbackDsId }),
    queryFn: queryFn(dsId),
    select: selectFn,
  })
}
