import {
  settingsBasicRouteRegExp,
  settingsFullRouteRegExp,
  settingsWorkspaceOnlyRouteRegExp,
} from "./regularExpressions"

export function isSettingsRoute(pathname: string) {
  return (
    settingsBasicRouteRegExp.test(pathname) ||
    settingsWorkspaceOnlyRouteRegExp.test(pathname) ||
    settingsFullRouteRegExp.test(pathname)
  )
}
