import { Supernova } from "@supernovaio/sdk"

import { getPartialBaseRoute } from "./getPartialBaseRoute"

export function getCreateWorkspaceRoute() {
  return "/welcome/create-workspace"
}

export async function getCreateProfileRoute(sdk: Supernova, wsId: string) {
  const route = await getPartialBaseRoute(sdk, wsId)

  return `${route}/welcome/create-profile`
}

export async function getSuccessRoute(sdk: Supernova, wsId: string) {
  const route = await getPartialBaseRoute(sdk, wsId)

  return `${route}/welcome/success`
}
