"use client"

import { useMemo } from "react"

import { useBrands } from "../../data/useBrands"
import { useDesignSystems } from "../../data/useDesignSystems"
import { useIsWorkspaceIpLocked } from "../../data/useIsWorkspaceLocked"
import { useMe } from "../../data/useMe"
import { useMemberships } from "../../data/useMemberships"
import { useVersions } from "../../data/useVersions"
import { type RecentPossibleBrandIds } from "../useRouteIds"

import {
  getPreparedWsId,
  getPreparedDsId,
  getPreparedVersionId,
  getPreparedBrandId,
  getPreparedIsIpLocked,
  getPreparedIsOnboardingFinished,
  getPreparedDefaultDestination,
} from "./helpers"
import type { RoutingResoluton, ResolverOptions } from "./types"

type Props = {
  wsId?: string
  dsId?: string
  versionId?: string
  brandId?: string
  possibleBrandIds?: RecentPossibleBrandIds
}

/*
 * Routing resolver.
 *
 * It accepts preferred unverified ids (for WS, DS, etc.) that are obtained from the client.
 * It returns actual ids of entities that are available to the current user.
 *
 * This returned data can be used to resolve the route.
 */
export function useRoutingResolver(
  { wsId, dsId, versionId, brandId, possibleBrandIds = [] }: Props,
  options: ResolverOptions = { includeOnlyOwnedWorkspaces: false }
): RoutingResoluton {
  const includeOnlyOwnedWorkspaces =
    "includeOnlyOwnedWorkspaces" in options
      ? options.includeOnlyOwnedWorkspaces
      : false
  const customWsId = "customWsId" in options ? options.customWsId : undefined
  const wsIdIsRequired = "customWsId" in options
  const resolvedWsId = customWsId ?? wsId

  const meResult = useMe()
  const preparedIsOnboardingFinished = getPreparedIsOnboardingFinished(meResult)
  const preparedDefaultDestination = getPreparedDefaultDestination(meResult)

  const membershipsFetchResult = useMemberships(meResult.data?.id, {
    filter: includeOnlyOwnedWorkspaces ? "only-owned" : "include-all",
  })
  const preparedWsId = getPreparedWsId(
    membershipsFetchResult,
    resolvedWsId,
    wsIdIsRequired
  )

  const workspaceIsIpLockedFetchResult = useIsWorkspaceIpLocked(
    preparedWsId || undefined
  )
  const preparedIsIpLocked = getPreparedIsIpLocked(
    workspaceIsIpLockedFetchResult,
    preparedWsId
  )
  const designSystemFetchResult = useDesignSystems(preparedWsId || undefined)

  const preparedDsId = getPreparedDsId(
    designSystemFetchResult,
    preparedWsId,
    dsId
  )

  const versionsFetchResult = useVersions(preparedDsId || undefined)

  const preparedVersionId = getPreparedVersionId(
    versionsFetchResult,
    preparedDsId,
    versionId
  )

  const brandsFetchResult = useBrands(
    preparedDsId || undefined,
    preparedVersionId || undefined
  )

  const preparedBrandId = getPreparedBrandId(
    brandsFetchResult,
    preparedVersionId,
    possibleBrandIds,
    brandId
  )

  return useMemo(() => {
    if (
      preparedWsId === null ||
      preparedIsIpLocked === null ||
      preparedDsId === null ||
      preparedVersionId === null ||
      preparedBrandId === null ||
      preparedIsOnboardingFinished === null ||
      preparedDefaultDestination === null
    ) {
      return undefined
    }

    return {
      wsId: preparedWsId,
      dsId: preparedDsId,
      versionId: preparedVersionId,
      brandId: preparedBrandId,
      isIpLocked: !!preparedIsIpLocked,
      isOnboardingFinished: !!preparedIsOnboardingFinished,
      preferredDefaultDestination: preparedDefaultDestination,
    }
  }, [
    preparedWsId,
    preparedIsIpLocked,
    preparedDsId,
    preparedVersionId,
    preparedBrandId,
    preparedIsOnboardingFinished,
    preparedDefaultDestination,
  ])
}
