import { useId } from "react"

import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"

import { useSafeQuery } from "../useSafeQuery"

export const createVersionQueryKey = (ids: {
  dsId: string
  versionId: string
}) =>
  [
    { dsId: ids.dsId },
    "version",
    {
      versionId: ids.versionId,
    },
  ] as const

export const useVersion = (
  dsId: string | undefined,
  versionId: string | undefined
) => {
  const dsIdFallback = useId()
  const versionIdFallback = useId()

  return useSafeQuery({
    queryKey: createVersionQueryKey({
      dsId: dsId || dsIdFallback,
      versionId: versionId || versionIdFallback,
    }),
    queryFn: async () => {
      if (!dsId || !versionId) {
        return null
      }

      const sdk = await getClientSdk()

      return sdk.versions.getVersion({
        designSystemId: dsId,
        versionId,
      })
    },
  })
}
