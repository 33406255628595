export function normalizeParams<P>(params: P) {
  const { wsId, dsId, brandId, versionId, ...rest } = params as {
    wsId?: string
    dsId?: string
    brandId?: string
    versionId?: string
  }

  return {
    ...rest,
    wsId: wsId?.split("-")[0],
    dsId: dsId?.split("-")[0],
    brandId: brandId?.split("-")[0],
    versionId: versionId?.split("-")[0],
  } as P
}
