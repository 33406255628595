import { useCallback, useId } from "react"

import { createWorkspaceQueryKey } from "@supernovaio/cloud/hooks/data/useWorkspace"
import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"
import {
  WorkspaceSubscription,
  WorkspaceSubscriptionPlanInterval,
  WorkspaceSubscriptionProductCode,
  WorkspaceSubscriptionStatus,
} from "@supernovaio/sdk"

import { useQueryClient, UseQueryResult } from "@tanstack/react-query"

import { useSafeQuery } from "../useSafeQuery"

import { useSubscriptionProductFeatures } from "./useSubscriptionProductFeatures"
import { useWorkspaceAvailableProducts } from "./useWorkspaceAvailableProducts"

import { pick } from "lodash"

type QueryIds = {
  workspaceId: string
}

type EffectiveDataProperties = Pick<
  WorkspaceSubscription,
  | "featuresSummary"
  | "stripeProductAdditionalFeatures"
  | "stripeProductFeatures"
  | "product"
  | "planInterval"
  | "seats"
  | "seatLimit"
  | "status"
  | "isPricePerCreator"
>

export type EffectiveWorkspaceSubscription = EffectiveDataProperties & {
  rawRemoteSubscription: WorkspaceSubscription
}

export const createSubscriptionQueryKey = (ids: QueryIds) => [
  ...createWorkspaceQueryKey({
    wsId: ids.workspaceId,
  }),
  "subscription",
]

export const useInvalidateWorkspaceSubscription = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (ids: QueryIds) =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: createSubscriptionQueryKey(ids),
        }),
      ]),
    [queryClient]
  )
}

export const useWorkspaceSubscription = ({
  workspaceId,
}: {
  workspaceId: string | undefined
}): UseQueryResult<EffectiveWorkspaceSubscription, Error> => {
  const workspaceIdFallback = useId()
  const { data: products } = useWorkspaceAvailableProducts({
    workspaceId,
  })

  const { data: productFeatures } = useSubscriptionProductFeatures(workspaceId)

  const response = useSafeQuery({
    queryKey: createSubscriptionQueryKey({
      workspaceId: workspaceId || workspaceIdFallback,
    }),
    queryFn: async () => {
      if (!workspaceId) {
        return null
      }

      const sdk = await getClientSdk()

      return sdk.workspaces.workspaceSubscription(workspaceId)
    },
  })

  if (!response.data) {
    return response as UseQueryResult<EffectiveWorkspaceSubscription, Error>
  }

  let effectiveData = pick(response.data, [
    "status",
    "seats",
    "seatLimit",
    "planInterval",
    "isPricePerCreator",
    "product",
    "featuresSummary",
    "stripeProductAdditionalFeatures",
    "stripeProductFeatures",
  ]) as EffectiveDataProperties

  // For suspended workspace we need to use free product features
  if (response.data.status === WorkspaceSubscriptionStatus.suspended) {
    const price = products
      ?.find(
        (product) => product.code === WorkspaceSubscriptionProductCode.free
      )
      ?.prices.find((_price) => _price.active && !_price.legacyVersion)

    effectiveData = {
      ...effectiveData,
      seats: 1,
      seatLimit: 1,
      isPricePerCreator: true,
      planInterval: WorkspaceSubscriptionPlanInterval.yearly,
      product: WorkspaceSubscriptionProductCode.free,
      featuresSummary: productFeatures?.free ?? {},
      stripeProductAdditionalFeatures:
        price?.stripeProductAdditionalFeatures ?? [],
      stripeProductFeatures: price?.stripeProductFeatures ?? [],
    }
  }

  // Raw data are non modified data from backend. We can use them for billing page.
  // Effective data are modified based on status, for example suspended workspace displays free plan.
  return {
    ...response,
    data: {
      ...effectiveData,
      rawRemoteSubscription: response.data,
    },
  } as UseQueryResult<EffectiveWorkspaceSubscription, Error>
}
