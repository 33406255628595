import { useId } from "react"

import { createWorkspaceQueryKey } from "@supernovaio/cloud/hooks/data/useWorkspace"
import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"

import { useSafeQuery } from "../useSafeQuery"

type QueryIds = {
  workspaceId: string
}

export const createAvailableProductsQueryKey = (ids: QueryIds) => [
  ...createWorkspaceQueryKey({
    wsId: ids.workspaceId,
  }),
  "available-products",
]

export const useWorkspaceAvailableProducts = ({
  workspaceId,
}: {
  workspaceId: string | undefined
}) => {
  const workspaceIdFallback = useId()

  return useSafeQuery({
    queryKey: createAvailableProductsQueryKey({
      workspaceId: workspaceId || workspaceIdFallback,
    }),

    queryFn: async () => {
      if (!workspaceId) {
        return []
      }

      const sdk = await getClientSdk()

      return sdk.workspaces.workspaceAvailableProducts(workspaceId)
    },
  })
}
