"use client"

import { useMemo } from "react"

import * as z from "zod"

type StorageLoadResult = {
  [key: string]: object | string | number | boolean | undefined
}

type PossibleCacheReturnTypes = object | string | number | boolean | null

type PersistOptions = { soft?: boolean }

export type CustomPersistentStorage = {
  persist: (options?: PersistOptions) => void
  load: () => StorageLoadResult
  purgeCache: () => void
  wipe: () => void
  getItem: (key: string) => PossibleCacheReturnTypes
  removeItem: (key: string) => void
  getAll: () => StorageLoadResult
  setItem: <T extends object>(
    key: string,
    value: string | number | boolean | T
  ) => void
}

const getDefaultStorage = (): Storage => {
  if (typeof window !== "undefined") {
    return window.localStorage
  }

  return {
    getItem: () => null,
    removeItem: () => {},
    setItem: () => {},
    key: () => null,
    clear: () => {},
    length: 0,
  }
}

export const PERSISTANCE_STORAGE_BASE_KEY = "supernova-cloud-cache" as const

// NOTE: possible memory leak but should be OK
let storageCache: StorageLoadResult | null = null

export function usePersistentStorage(
  // NOTE: make possible to pass a mock for testing
  options?: { storageImplementationFactory?: () => Storage }
) {
  return useMemo(() => getPersistentStorage(options), [options])
}

export function getPersistentStorage(
  // NOTE: make possible to pass a mock for testing
  options?: {
    enableLogging?: boolean
    storageImplementationFactory?: () => Storage
  }
): CustomPersistentStorage {
  const storageImplementation =
    options?.storageImplementationFactory?.() || getDefaultStorage()

  const log = options?.enableLogging ? console.info.bind(console) : () => {}
  const warn = options?.enableLogging ? console.warn.bind(console) : () => {}

  return {
    purgeCache() {
      storageCache = null
    },
    wipe() {
      storageImplementation.removeItem(PERSISTANCE_STORAGE_BASE_KEY)
      this.purgeCache()
    },
    setItem(key: string, value: string | number | boolean | object) {
      const cache = this.load()

      cache[key] = value
    },
    removeItem(key: string) {
      const cache = this.load()

      delete cache[key]
    },
    persist(options?: PersistOptions) {
      if (!storageCache) {
        return
      }

      try {
        storageImplementation.setItem(
          PERSISTANCE_STORAGE_BASE_KEY,
          JSON.stringify(storageCache)
        )

        // NOTE: when soft: true is passed, the in-memory cache is not purged
        if (!options?.soft) {
          this.purgeCache()
        }
      } catch (err) {
        warn("Storage persistance: could not persist the storage cache", err)
      }
    },
    getAll() {
      return this.load()
    },
    load() {
      if (storageCache) {
        return storageCache
      }

      try {
        const attemptedStoredData = JSON.parse(
          storageImplementation.getItem(PERSISTANCE_STORAGE_BASE_KEY) || "{}"
        ) as null | undefined | StorageLoadResult

        storageCache = attemptedStoredData || {}
        return storageCache
      } catch (err) {
        log(
          "usePersistentStorage: cannot parse basic data from storage:",
          PERSISTANCE_STORAGE_BASE_KEY,
          err
        )
        storageCache = {}
        return storageCache
      }
    },
    getItem(key: string): PossibleCacheReturnTypes {
      const storedCache = this.load()

      if (key in storedCache) {
        log("usePersistentStorage: cache HIT, key:", key)
      }

      const cachedData = storedCache[key] ?? null

      return cachedData
    },
  }
}

export function useStorageValueMap<T extends z.ZodRawShape>(
  ZodSchema: z.ZodObject<T>,
  selectorFn: (
    storage: CustomPersistentStorage
  ) => ReturnType<CustomPersistentStorage["getItem"]>,
  options?: { storageImplementationFactory?: () => Storage }
) {
  const storage = usePersistentStorage(options)
  // NOTE: only depends on storage in this context and storage is always the same.
  const memoizedSelectorFm = useMemo(() => selectorFn, [storage])

  return useMemo<
    <MR>(
      genericMapper: (successfulParsingResult: z.ZodObject<T>["_output"]) => MR
    ) => MR | undefined
  >(
    () =>
      <MR>(
        mapper: (successfulParsingResult: z.ZodObject<T>["_output"]) => MR
      ): MR | undefined => {
        const result: unknown = memoizedSelectorFm(storage)
        const zodResult = ZodSchema.safeParse(result)

        if (zodResult.success) {
          return mapper(zodResult.data)
        }
      },
    [storage, ZodSchema, memoizedSelectorFm]
  )
}
