import {
  Brand,
  DesignSystem,
  DesignSystemVersion,
  Workspace,
} from "@supernovaio/sdk"

import type { RoutingResoluton } from "../../hooks/routing/useRoutingResolution"
import { getClientSdk } from "../data/getClientSdk"

import { getBrandRoute } from "./getBrandRoute"
import { getReadableRoute } from "./getReadableRoute"
import {
  settingsBasicRouteRegExp,
  settingsFullRouteRegExp,
  settingsWorkspaceOnlyRouteRegExp,
} from "./regularExpressions"
import {
  BasicSettingsRouteType,
  SettingsRouteDestination,
  SettingsRouteType,
} from "./types"

export const supportedDestinations = {
  basic: [SettingsRouteDestination.userProfile],
  workspaceOnly: [
    SettingsRouteDestination.userProfile,
    SettingsRouteDestination.wsBilling,
    SettingsRouteDestination.wsGeneral,
    SettingsRouteDestination.wsIpWhitelisting,
    SettingsRouteDestination.members,
  ],
}

async function getSettingsFullRouteFromIds(
  ws: string,
  ds: string,
  version: string,
  brand: string,
  destination: SettingsRouteDestination
): Promise<SettingsRouteType> {
  const brandRoute = await getBrandRoute(ws, ds, version, brand)

  return `${brandRoute}/settings/${destination}`
}

async function getSettingsWorkspaceOnlyRoute(
  wsId: string,
  destination: SettingsRouteDestination
): Promise<SettingsRouteType> {
  const sdk = await getClientSdk()
  const workspace = await sdk.workspaces.workspace(wsId)
  let resolvedDestination = destination

  if (!supportedDestinations.workspaceOnly.includes(destination)) {
    resolvedDestination = SettingsRouteDestination.userProfile
  }

  return `${getReadableRoute(workspace)}/settings/${resolvedDestination}`
}

function getSettingsBasicRoute(
  destination: SettingsRouteDestination
): BasicSettingsRouteType {
  let resolvedDestination = destination

  if (!supportedDestinations.basic.includes(destination)) {
    resolvedDestination = SettingsRouteDestination.userProfile
  }

  return `/settings/${resolvedDestination}`
}

async function getSettingsRoutesVariants({
  resolution,
  destination,
}: {
  resolution: RoutingResoluton
  destination?: SettingsRouteDestination
}) {
  if (!resolution) {
    return undefined
  }

  const settingsFullRoute =
    resolution.wsId &&
    resolution.dsId &&
    resolution.versionId &&
    resolution.brandId &&
    (await getSettingsFullRouteFromIds(
      resolution.wsId,
      resolution.dsId,
      resolution.versionId,
      resolution.brandId,
      destination ?? SettingsRouteDestination.userProfile
    ))

  const settingsWorkspaceOnlyRoute =
    resolution.wsId &&
    (!resolution.dsId || !resolution.versionId || !resolution.brandId)
      ? await getSettingsWorkspaceOnlyRoute(
          resolution.wsId,
          destination ?? SettingsRouteDestination.userProfile
        )
      : undefined

  const settingsBasicRoute = !resolution.wsId
    ? getSettingsBasicRoute(destination ?? SettingsRouteDestination.userProfile)
    : undefined

  return {
    settingsFullRoute,
    settingsWorkspaceOnlyRoute,
    settingsBasicRoute,
  }
}

/**
 * Used only on settings pages for internal redirect within settings
 * */
export async function getSettingsRouteForInternalRedirect({
  resolution,
  pathname,
  segment,
}: {
  resolution: RoutingResoluton
  pathname: string
  segment: string | null
}) {
  if (!resolution) {
    return undefined
  }

  const routesVariants = await getSettingsRoutesVariants({
    resolution,
    destination: segment as SettingsRouteDestination,
  })

  if (!routesVariants) {
    return undefined
  }

  const { settingsBasicRoute, settingsWorkspaceOnlyRoute, settingsFullRoute } =
    routesVariants

  if (settingsFullRouteRegExp.test(pathname)) {
    if (settingsWorkspaceOnlyRoute) {
      return settingsWorkspaceOnlyRoute
    }

    if (settingsBasicRoute) {
      return settingsBasicRoute
    }
  } else if (settingsWorkspaceOnlyRouteRegExp.test(pathname)) {
    if (settingsFullRoute && resolution.isOnboardingFinished) {
      return settingsFullRoute
    }

    if (settingsBasicRoute) {
      return settingsBasicRoute
    }
  } else if (settingsBasicRouteRegExp.test(pathname)) {
    if (settingsFullRoute && resolution.isOnboardingFinished) {
      return settingsFullRoute
    }

    if (settingsWorkspaceOnlyRoute) {
      return settingsWorkspaceOnlyRoute
    }
  }

  return undefined
}

export function getSettingsRoute(
  workspace: Workspace,
  designSystem: DesignSystem,
  version: DesignSystemVersion,
  brand: Brand,
  destination: SettingsRouteDestination
) {
  const baseRoute = getReadableRoute(workspace, designSystem, version, brand)

  return `${baseRoute}/settings/${destination}`
}
