import { entityAlphabeticalSort } from "@supernovaio/dm/src/utils/sort"

import {
  Brand,
  DesignSystem,
  DesignSystemVersion,
  User,
  UserOnboardingDefaultDestination,
} from "@supernovaio/sdk"

import type { UseQueryResult } from "@tanstack/react-query"

import type { EffectiveMembership } from "../../data/useMemberships"
import type { RecentPossibleBrandIds } from "../useRouteIds"

export function getPreparedWsId(
  result: UseQueryResult<EffectiveMembership[], Error>,
  wsId: string | undefined,
  wsIdRequired = false
) {
  if (result.isFetched) {
    let localWsId

    // If we have wsId, let's try to find related workspace.
    if (wsId) {
      localWsId = result.data?.find(({ workspace }) => workspace.id === wsId)
        ?.workspace.id
    }

    // If customWsId option was used in the resolver, we require workspace to be found in previous step.
    if (wsIdRequired && !localWsId) {
      return undefined
    }

    // If workspace wasn't found and customWsId wasn't used return first available workspace.
    if (!localWsId) {
      localWsId = entityAlphabeticalSort(
        result.data?.map(({ workspace }) => workspace) || [],
        ({ profile }) => profile.name
      )[0]?.id
    }

    return localWsId
  }

  return null
}

export function getPreparedIsIpLocked(
  result: UseQueryResult<boolean | null>,
  preparedWsId: string | undefined | null
) {
  if (result.isFetched) {
    let localIsIpLocked

    if (preparedWsId) {
      localIsIpLocked = !!result.data
    }

    return localIsIpLocked
  }

  return null
}

export function getPreparedDsId(
  result: UseQueryResult<DesignSystem[], Error>,
  preparedWsId: string | undefined | null,
  dsId: string | undefined
) {
  if (result.isFetched && preparedWsId !== null) {
    let localDsId

    if (dsId) {
      localDsId = result.data?.find((ds) => ds.id === dsId)?.id
    }

    if (!localDsId) {
      localDsId = entityAlphabeticalSort(result.data || [], (ds) => ds.name)[0]
        ?.id
    }

    return localDsId
  }

  return null
}

export function getPreparedVersionId(
  result: UseQueryResult<DesignSystemVersion[], Error>,
  preparedDsId: string | undefined | null,
  versionId: string | undefined
) {
  if (result.isFetched && preparedDsId !== null) {
    let localVersionId: string | undefined

    if (!versionId) {
      const versionData = result.data || []

      // NOTE: RCT-290 - pick the first shared draft version if available
      localVersionId =
        versionData.filter((version) => !version.isReadonly)[0]?.id ||
        entityAlphabeticalSort(versionData, (vs) => vs.name)[0]?.id
    }

    if (!localVersionId) {
      localVersionId = result.data?.find((vs) => vs.id === versionId)?.id
    }

    return localVersionId
  }

  return null
}

export function getPreparedBrandId(
  result: UseQueryResult<Brand[], Error>,
  preparedVersionId: string | undefined | null,
  recentPossibleBrandIds: RecentPossibleBrandIds,
  brandId: string | undefined
) {
  if (result.isFetched && preparedVersionId !== null) {
    let localBrandId

    if (!brandId) {
      localBrandId = recentPossibleBrandIds.find(
        (recentPossibleBrand) =>
          recentPossibleBrand.versionId === preparedVersionId &&
          result.data?.some(
            (brand) => brand.idInVersion === recentPossibleBrand.brandId
          )
      )?.brandId
    }

    if (!localBrandId) {
      localBrandId = result.data?.find(
        (brnd) => brnd.idInVersion === brandId
      )?.idInVersion
    }

    if (!localBrandId) {
      localBrandId = (result.data || [])[0]?.idInVersion
    }

    return localBrandId
  }

  return null
}

export function getPreparedIsOnboardingFinished(
  result: UseQueryResult<User, unknown>
) {
  if (result.isFetched) {
    return result.data?.profile.isOnboardingFinished
  }

  return null
}

export function getPreparedDefaultDestination(
  result: UseQueryResult<User, unknown>
): UserOnboardingDefaultDestination | "unknownDestination" | null {
  if (result.isFetched && result.data) {
    // for backward compatibility, we need to check if the user has the onboarding destination
    if (!result.data.profile.onboarding?.defaultDestination) {
      return "unknownDestination"
    }

    return result.data.profile.onboarding?.defaultDestination
  }

  return null
}
