import {
  Brand,
  DesignSystem,
  DesignSystemVersion,
  Workspace,
} from "@supernovaio/sdk"

import { BaseRouteType } from "./types"

import { kebabCase } from "lodash"

export function getReadableRoute(
  workspace?: Workspace | null,
  designSystem?: DesignSystem | null,
  version?: DesignSystemVersion | null,
  brand?: Brand | null
): BaseRouteType {
  if (workspace && designSystem && version && brand) {
    return `/${workspace.id}-${getUrlSlug(workspace?.profile.name)}/${
      designSystem.id
    }-${getUrlSlug(designSystem.name)}/${getVersionSlug(version)}/${
      brand.idInVersion
    }-${getUrlSlug(brand.name)}`
  }

  if (workspace && designSystem && version) {
    return `/${workspace.id}-${getUrlSlug(workspace?.profile.name)}/${
      designSystem.id
    }-${getUrlSlug(designSystem.name)}/${getVersionSlug(version)}`
  }

  if (workspace && designSystem) {
    return `/${workspace.id}-${getUrlSlug(workspace?.profile.name)}/${
      designSystem.id
    }-${getUrlSlug(designSystem.name)}`
  }

  if (workspace) {
    return `/${workspace.id}-${getUrlSlug(workspace?.profile.name)}`
  }

  return "/" as const
}

export function getUrlSlug(rawValue: string): string {
  // Convert to kebab case, ensure it's lowercase, contains only Latin letters, digits, and hyphens
  let slug = kebabCase(rawValue)
    .toLowerCase()
    .replace(/[^a-z0-9-]/g, "")

  // Remove leading and trailing hyphens
  slug = slug.replace(/^-+|-+$/g, "")

  return slug
}

// if version name is not provided, it will be omitted from the slug
function getVersionSlug(version: DesignSystemVersion): string {
  if (version.name) {
    return `${version.id}-${getUrlSlug(version.name)}`
  }

  if (version.version) {
    return `${version.id}-version-${getUrlSlug(version.version)}`
  }

  return version.id
}
