import { signIn } from "next-auth/react"
import { useSearchParams } from "next/navigation"

import { generateAuthParams } from "@supernovaio/cloud/features/auth"
import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"
import { isUnauthorizedError } from "@supernovaio/cloud/utils/errorHandler"
import { User } from "@supernovaio/sdk"

import { useQueryClient, UseQueryOptions } from "@tanstack/react-query"

import { useSafeQuery } from "../useSafeQuery"

export const meQueryKey = ["me"]

export const useInvalidateMe = () => {
  const queryClient = useQueryClient()

  return () =>
    queryClient.invalidateQueries({
      queryKey: meQueryKey,
    })
}

export const useMe = (
  options?: Omit<UseQueryOptions<User, unknown, User, string[]>, "initialData">
) => {
  const searchParams = useSearchParams()

  return useSafeQuery({
    ...options,
    queryKey: meQueryKey,
    queryFn: async () => {
      const sdk = await getClientSdk()

      try {
        return await sdk.me.me()
      } catch (e) {
        // Temporary fix for unauthorized for multiple users
        if (isUnauthorizedError(e)) {
          await signIn(
            "supernovaauth",
            undefined,
            generateAuthParams(
              searchParams.get("email"),
              searchParams.get("sso_provider")
            )
          )
        }

        throw e
      }
    },
  })
}
