"use client"

import { useEffect, useState } from "react"

import ErrorBoundaryView from "@supernovaio/cloud/ui/errors/ErrorBoundaryView"
import { handleError } from "@supernovaio/cloud/utils/errorHandler"

import { useClientSideRedirect } from "../../hooks/useClientSideRedirect"
import { PageLayoutTypeCentering } from "../../ui/page/layouts/PageLayoutTypeCentering"
import PageLoader from "../../ui/page/loaders/PageLoader"

export default function RootPage() {
  const { resolution } = useClientSideRedirect()
  const [redirectError, setRedirectError] = useState<Error | undefined>()

  // Super hacky way to handle the case when the client side redirect is not working, needs to be refactored
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const error = new Error("Redirect issue - please, contact support")
      handleError(error, resolution)

      setRedirectError(error)
    }, 15000)

    return () => clearTimeout(timeoutId)
  }, [resolution])

  if (redirectError) {
    return <ErrorBoundaryView error={redirectError} />
  }

  return (
    <PageLayoutTypeCentering>
      <PageLoader showTitle />
    </PageLayoutTypeCentering>
  )
}
