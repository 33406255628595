import * as z from "zod"

export const recentRouteIdsSchema = z.object({
  wsIds: z.array(z.object({ wsId: z.string() })),
  dsIds: z.array(z.object({ parentWsId: z.string(), dsId: z.string() })),
  brandIds: z.array(
    z.object({
      parentDsId: z.string(),
      parentVersionId: z.string(),
      brandId: z.string(),
    })
  ),
  documentationPageIds: z.array(
    z.object({
      parentVersionId: z.string(),
      parentBrandId: z.string(),
      documentationPageId: z.string(),
    })
  ),
})
