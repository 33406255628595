import { useId } from "react"

import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"

import { useSafeQuery } from "../useSafeQuery"

import { createWorkspaceQueryKey } from "./useWorkspace"

type QueryIds = {
  workspaceId: string
}

export const createWorkspaceProductFeaturesQueryKey = (ids: QueryIds) => [
  ...createWorkspaceQueryKey({
    wsId: ids.workspaceId,
  }),
  "productFeatures",
]

/**
 * A hook that returns a list of product features for all recent products.
 * Important: this hook should be used ONLY to get data for the products available for upgrade to.
 * To get the list of features for the current subscription, use {@link useWorkspaceSubscription} hook.
 * Reason: {@link useSubscriptionProductFeatures} hook doesn't work properly with legacy or suspended subscription.
 * @param workspaceId - Workspace ID
 * @returns Product features grouped by product code
 */
export const useSubscriptionProductFeatures = (
  workspaceId: string | undefined
) => {
  const workspaceIdFallback = useId()

  return useSafeQuery({
    queryKey: createWorkspaceProductFeaturesQueryKey({
      workspaceId: workspaceId || workspaceIdFallback,
    }),
    queryFn: async () => {
      if (!workspaceId) {
        return null
      }

      const sdk = await getClientSdk()

      return sdk.workspaces.workspaceProductFeatures(workspaceId)
    },
  })
}
