import { useId, useCallback } from "react"

import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"

import { useQueryClient } from "@tanstack/react-query"

import { useSafeQuery } from "../useSafeQuery"

export const createDesignSystemsQueryKey = (ids: { wsId: string }) => [
  "designSystems",
  { ...ids },
]

export const useInvalidateDesignSystems = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (ids: { wsId: string }) =>
      queryClient.invalidateQueries({
        queryKey: createDesignSystemsQueryKey(ids),
      }),

    [queryClient]
  )
}

export const useDesignSystems = (wsId?: string) => {
  const fallbackWsId = useId()

  return useSafeQuery({
    queryKey: createDesignSystemsQueryKey({ wsId: wsId || fallbackWsId }),
    queryFn: async () => {
      if (!wsId) {
        return []
      }

      const sdk = await getClientSdk()

      return sdk.designSystems.designSystems(wsId)
    },
  })
}

export const useWorkspaceDesignSystemsPrefetcher = () => {
  const queryClient = useQueryClient()

  return (wsId: string) => {
    return queryClient.prefetchQuery({
      queryKey: createDesignSystemsQueryKey({ wsId }),
      queryFn: async () => {
        const sdk = await getClientSdk()

        // Hack for infinite rendering loop in locked ip context
        try {
          return await sdk.designSystems.designSystems(wsId)
        } catch {
          return []
        }
      },
    })
  }
}
