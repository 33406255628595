//
//  SDKWorkspaceSubscriptionPlanInterval.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Workspace subscription status internal enum

export enum WorkspaceSubscriptionPlanInterval {
  /** The workspace is on a yearly plan */
  yearly = "yearly",

  /** The workspace is on monthly plan */
  monthly = "monthly",
}
