import { notFound } from "next/navigation"

import {
  is404Error,
  isBlockedIpError,
  isForbiddenAccessError,
  isUnauthorizedError,
} from "@supernovaio/cloud/utils/errorHandler"

import {
  useQuery,
  type DefaultError,
  type DefinedInitialDataOptions,
  type DefinedUseQueryResult,
  type QueryClient,
  type QueryKey,
  type UndefinedInitialDataOptions,
  type UseQueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query"

type Options<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> =
  | (UndefinedInitialDataOptions<TQueryFnData, TError, TData, TQueryKey> &
      CustomOptions)
  | (DefinedInitialDataOptions<TQueryFnData, TError, TData, TQueryKey> &
      CustomOptions)
  | UseSafeQueryOptions<TQueryFnData, TError, TData, TQueryKey>

type CustomOptions = {
  handle404Error?: boolean
  handleForbiddenAccessError?: boolean
}

export type UseSafeQueryOptions<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & CustomOptions

/**
 * The function is identical to original useQuery with one difference.
 * If query will return error which can be considered as 404 or forbidden access error, then we will throw not found error to show not-found page.
 */
export function useSafeQuery<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: UndefinedInitialDataOptions<TQueryFnData, TError, TData, TQueryKey> &
    CustomOptions,
  queryClient?: QueryClient
): UseQueryResult<TData, TError>

export function useSafeQuery<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: DefinedInitialDataOptions<TQueryFnData, TError, TData, TQueryKey> &
    CustomOptions,
  queryClient?: QueryClient
): DefinedUseQueryResult<TData, TError>

export function useSafeQuery<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: UseSafeQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  queryClient?: QueryClient
): UseQueryResult<TData, TError>

export function useSafeQuery<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: Options<TQueryFnData, TError, TData, TQueryKey>,
  queryClient?: QueryClient
) {
  const mergedOptions: Options<TQueryFnData, TError, TData, TQueryKey> = {
    handle404Error: true,
    handleForbiddenAccessError: true,
    retry: (_failureCount, error) => {
      // Don't try again if error is related to ipallowlist
      return !isBlockedIpError(error)
    },
    ...options,
  }
  const result = useQuery(mergedOptions, queryClient)

  if (isUnauthorizedError(result.error)) {
    return result
  }

  // If we are blocked by ip, we should return results and allow different part of application to handle this situation
  if (isBlockedIpError(result.error)) {
    return result
  }

  if (mergedOptions.handle404Error && is404Error(result.error)) {
    notFound()
  }

  if (
    mergedOptions.handleForbiddenAccessError &&
    isForbiddenAccessError(result.error)
  ) {
    notFound()
  }

  return result
}
