"use client"

import { useMe } from "./data/useMe"
import { useMemberships } from "./data/useMemberships"

export function useCurrentMembership({ wsId }: { wsId: string | undefined }) {
  const { data: me } = useMe()
  const { data: memberships, isPending } = useMemberships(me?.id)

  return {
    membership: memberships?.find(
      (membership) => membership.workspace.id === wsId
    ),
    // we need to use isPending instead of isLoading since isLoading is initially false on disabled query.
    isPending,
  }
}
