//
//  SDKWorkspaceSubscriptionProduct.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Workspace subscription product enum

export enum WorkspaceSubscriptionProductCode {
  /** The workspace is on free tier */
  free = "free",

  /** The workspace is on team tier */
  team = "team",

  /** The workspace is on team test tier */
  teamTest = "team_test",

  /** The workspace is on company tier */
  company = "company",

  /** The workspace is on enterprise tier */
  enterprise = "enterprise",
}
