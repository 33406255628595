import { useCallback } from "react"

import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"
import { UserRole, type Membership } from "@supernovaio/sdk"

import { useQueryClient, UseQueryResult } from "@tanstack/react-query"

import { useSafeQuery } from "../useSafeQuery"

export const membershipsQueryKey = ["memberships"]

export type EffectiveMembership = Pick<Membership, "workspace" | "role">

export function useInvalidateMemberships() {
  const queryClient = useQueryClient()

  return useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: membershipsQueryKey,
      }),
    [queryClient]
  )
}

export const useMemberships = (
  userId: string | undefined,
  options: {
    /**
     * Filter "only-owned" filters out all memberships where effective user role is not owner.
     * @default "include-all"
     */
    filter: "only-owned" | "include-all"
  } = { filter: "include-all" }
): UseQueryResult<EffectiveMembership[], Error> => {
  return useSafeQuery({
    enabled: !!userId,
    queryKey: membershipsQueryKey,
    queryFn: async () => {
      if (!userId) {
        return []
      }

      const sdk = await getClientSdk()

      const rawRemoteMemberships = await sdk.workspaces.memberships(userId)

      const memberships: EffectiveMembership[] = rawRemoteMemberships.map(
        (m) => ({
          workspace: m.workspace,
          role: m.effectiveRole,
        })
      )

      return memberships
    },
    select(memberships) {
      if (options.filter === "only-owned") {
        return memberships.filter(
          (membership) => membership.role === UserRole.owner
        )
      }

      return memberships
    },
  })
}
